'use client'
import React, { useRef, useState } from 'react';
import ProjectCard from '@components/ProjectCard';
// import ProjectCardNew from '@components/PropertyCardNew';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules'
import 'swiper/css';
import 'swiper/css/navigation';
import Link from 'next/link';
import LinkComponent from '@components/LinkComponent';


const ProjectCardSlider = ({ title, items, url, cardType, icon, dictionary, lang, pl = 3 }) => {
    return (
        <section className={`w-full pl-3 mt-3 pb-3 border-b-[1px] border-b-forth`}>
            {url ? (
                <Link href={`/${url}`}>
                    <h2 className='text-maincolor font-bold text-xl mb-2'>{title?.[lang]}</h2>
                </Link>
            ) : (
                <h2 className='text-maincolor font-bold text-xl mb-2'>{title?.[lang]}</h2>
            )}
            <div className='sm:block hidden'>
                <Swiper
                    slidesPerView={'auto'}
                    // spaceBetween={8}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Navigation]}
                    className=''
                >
                    {items.map((item, index) => (
                        <SwiperSlide key={index} className='w-[250px] property-swiper-slide'>
                            <ProjectCard item={item} url={url} lang={lang} dictionary={dictionary} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            {items && (
                <div className='sm:hidden block'>
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={1.5}
                    >
                        {items.map((item, index) => (
                            <SwiperSlide key={index}>
                                <ProjectCard item={item} url={url} lang={lang} dictionary={dictionary} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            )}
            {cardType === 'newdevelopment' && (
                <div className='w-full flex items justify-center'>
                    <LinkComponent href={'/new-developments'} className={'bg-maincolor text-white px-2 py-1 sm:my-4 my-2 rounded-md'}>{dictionary.card.seeAllNewDevelopments}</LinkComponent>
                </div>
            )}
        </section>
    );
};

export default ProjectCardSlider;