import(/* webpackMode: "eager" */ "/app/components/DistrictCardSlider.js");
import(/* webpackMode: "eager" */ "/app/components/FooterPopularLinks.js");
import(/* webpackMode: "eager" */ "/app/components/Hero.js");
import(/* webpackMode: "eager" */ "/app/components/LinkComponent.js");
import(/* webpackMode: "eager" */ "/app/components/Nav.js");
import(/* webpackMode: "eager" */ "/app/components/PersonalizedServiceForm.js");
import(/* webpackMode: "eager" */ "/app/components/ProjectCardSlider.js");
import(/* webpackMode: "eager" */ "/app/components/PropertyCardSlider.js");
import(/* webpackMode: "eager" */ "/app/components/SellFormHome.js");
import(/* webpackMode: "eager" */ "/app/components/TransportCardSlider.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js")